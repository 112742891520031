<script>
import Layout from "../../layouts/main.vue";
import PageHeader from "@/components/page-header";
import Swal from "sweetalert2";
import axios from "axios";
import {errorCatcher} from "@/helpers/error-catcher";
import {paginationHelper} from "@/helpers/pagination-helper";
import {dateUtil} from "@/helpers/date-util";
import {required} from "vuelidate/lib/validators";
import OrdersTable from "../../../components/shop/orders-table.vue";
import {priceHelper} from "../../../helpers/price-helper";

/**
 * Core Show Shop Component
 */
export default {
  computed: {
    priceHelper() {
      return priceHelper
    },

    paginationHelper() {
      return paginationHelper
    }
  },

  components: {
    OrdersTable,
    Layout,
    PageHeader
  },

  data() {

    return {
      submitted: false,

      shop: null,
      market: null,
      user: null,
      engines: null,
      industry: null,

      modals: {

        editElementInTable: {
          item: null,
          title: "",
          visibleModal: false,
          element: "",
          elementName: "",
          type: ""
        },

        addDeposit: {

          visible: false,

          form: {

            amount: 0,
            description: ""

          }

        },

        addShopCost: {

          visible: false,

          form: {

            cost: 0,
            description: ""

          }

        }

      },

      withdrawalsTable: {
        rows: 0,
        totalRows: 0,
        currentPage: 1,
        perPage: 100,
        pageOptions: [5, 10, 25, 50, 100],
        filter: null,
        filterOn: [],
        sortBy: "name",
        sortDesc: false,
        isBusy: false,
        inputPage: "",

        elements: []
      },

      depositsTable: {
        rows: 0,
        totalRows: 0,
        currentPage: 1,
        perPage: 100,
        pageOptions: [5, 10, 25, 50, 100],
        filter: null,
        filterOn: [],
        sortBy: "name",
        sortDesc: false,
        isBusy: false,
        inputPage: "",

        elements: []
      },

      withdrawalOrdersTable: {
        rows: 0,
        totalRows: 0,
        currentPage: 1,
        perPage: 100,
        pageOptions: [5, 10, 25, 50, 100],
        filter: null,
        filterOn: [],
        sortBy: "name",
        sortDesc: false,
        isBusy: false,
        inputPage: "",

        elements: []
      },

      shopCostsTable: {
        rows: 0,
        totalRows: 0,
        currentPage: 1,
        perPage: 100,
        pageOptions: [5, 10, 25, 50, 100],
        filter: null,
        filterOn: [],
        sortBy: "name",
        sortDesc: false,
        isBusy: false,
        inputPage: "",

        elements: []
      },

      changesHistoryTable: {
        rows: 0,
        totalRows: 0,
        currentPage: 1,
        perPage: 100,
        pageOptions: [5, 10, 25, 50, 100],
        filter: null,
        filterOn: [],
        sortBy: "name",
        sortDesc: false,
        isBusy: false,
        inputPage: "",

        elements: []
      },

      eWallet: {
        potentialProfit: 0.0,
        realProfit: 0.0,
        sumProfit: 0.0,
        payments: 0.0,
        balance: 0.0,
        costs: 0.0,
        withdrawals: 0.0,
        withdrawalRequests: 0.0,
        profitProgramPartner: 0.0
      }

    };

  },

  validations: {

    modals: {

      addDeposit: {

        form: {
          amount: {required},
          description: {required}
        }

      },

      addShopCost: {

        form: {
          cost: {required},
          description: {required}
        }

      }

    }

  },

  methods: {

    setToFirstPageAndRefresh(table, refName) {
      this.paginationHelper.setToFirstPageAndRefresh(this, table, refName)
    },

    getItems() {
      return [
        {
          text: "ecat"
        },
        {
          text: this.$t('core.shop.show.title', {'shop_name': this.shop ? this.shop.name : ''}),
          active: true
        }
      ]
    },

    async loadShop() {
      try {
        const id = this.$route.params.id;
        if (!id) {
          await Swal.fire("", "Nie znaleziono takiego sklepu!", "error");
          return;
        }

        const { data } = await axios.get(`/shop/${id}`, {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          },
          data: {}
        })

        this.shop = data
        await this.loadMarket()
        await this.loadEWallet()
        await this.loadUser()
        await this.loadIndustry()
      } catch (error) {
        errorCatcher.catchErrors(error)
      }
    },

    async loadMarket() {
      try {
        const { data } = await axios.get(`/market/${this.shop.marketId}`, {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          },
          data: {}
        })

        this.market = data
      } catch (error) {
        errorCatcher.catchErrors(error)
      }
    },

    async loadEWallet() {
      try {
        const { data } = await axios.get(`/ewallet/${this.shop.id}`, {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          },
          data: {}
        });

        this.eWallet = data
      } catch (error) {
        errorCatcher.catchErrors(error)
      }
    },

    async loadIndustry() {
      if (!this.shop.industryId) {
        return
      }

      try {
        const { data } = await axios.get(`/industry/${this.shop.industryId}`, {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          },
          data: {}
        })

        this.industry = data
      } catch (error) {
        errorCatcher.catchErrors(error)
      }
    },

    async loadUser() {
      try {
        const { data } = await axios.get(`/user/${this.shop.userId}`, {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          },
          data: {}
        })

        this.user = data
      } catch (error) {
        errorCatcher.catchErrors(error)
      }
    },

    async loadEngines() {
      try {
        const { data} = await axios.get(`/engine`, {
          data: {},
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          }
        });

        this.engines = new Map(data.map((obj) => [obj.id, obj]));
      } catch (error) {
        errorCatcher.catchErrors(error)
      }
    },

    getEngine(id) {
      if (!id || !this.engines) {
        return null
      }

      return this.engines.get(id) || null
    },

    hideEditElementInTableModal() {
      this.modals.editElementInTable.visibleModal = false
      this.modals.editElementInTable.item = null
      this.modals.editElementInTable.title = ""
      this.modals.editElementInTable.element = ""
      this.modals.editElementInTable.elementName = ""
      this.modals.editElementInTable.type = ""
    },

    openEditElementInTableModal(item, element, elementName, type, title) {
      this.modals.editElementInTable.item = Object.assign({}, item)
      this.modals.editElementInTable.element = element
      this.modals.editElementInTable.title = title
      this.modals.editElementInTable.elementName = elementName
      this.modals.editElementInTable.type = type
      this.modals.editElementInTable.visibleModal = true
    },

    editShop() {
      const json = JSON.stringify(this.modals.editElementInTable.item);

      axios.put(`/shop`, json, {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        }
      }).then((result) => {
        const lastIndustryId = this.shop.industryId
        this.shop = result.data

        if (lastIndustryId !== result.data.industryId) {
          this.loadIndustry()
        }

        Swal.fire("Sukces!", "Zaktualizowano informacje o sklepie", "success");
        this.hideEditElementInTableModal()
      }).catch((error) => {
        errorCatcher.catchErrors(error)
      })
    },

    getWithdrawalsTableFields() {
      return [
        {key: "shopName", label: 'Nazwa' },
        {key: "amount", label: 'Kwota', formatter: value => priceHelper.format(value, this.market.currency)},
        {key: "createdAt", label: 'Data', formatter: value => dateUtil.asDateTime(value) },
        {key: "actions", label: this.$t("table.actions") }
      ]
    },

    getDepositsTableFields() {
      return [
        {key: "shopName", label: 'Nazwa' },
        {key: "amount", label: 'Kwota', formatter: value => priceHelper.format(value, this.market.currency) },
        {key: "description", label: 'Opis' },
        {key: "createdAt", label: 'Data', formatter: value => dateUtil.asDateTime(value) },
        {key: "actions", label: this.$t("table.actions") }
      ]
    },

    getShopCostsTableFields() {
      return [
        {key: "shopName", label: 'Nazwa' },
        {key: "cost", label: 'Kwota', formatter: value => priceHelper.format(value, this.market.currency)},
        {key: "description", label: 'Opis' },
        {key: "createdAt", label: 'Data', formatter: value => dateUtil.asDateTime(value) },
        {key: "actions", label: this.$t("table.actions") }
      ]
    },

    getWithdrawalOrdersTableFields() {
      return [
        {key: "firstName", label: 'Imię'},
        {key: "lastName", label: 'Nazwisko'},
        {key: "nationalInsuranceNumber", label: 'Pesel lub NIP (w przypadku firmy)'},
        {key: "companyName", label: 'Nazwa firmy'},
        {key: "accountNumber", label: 'Nr konta'},
        {key: "amount", label: 'Kwota', formatter: value => priceHelper.format(value, this.market.currency)},
        {key: "status", label: 'Status'},
        {key: "createdAt", label: 'Data', formatter: value => dateUtil.asDateTime(value) },
        {key: "actions", label: this.$t("table.actions") }
      ]
    },

    getChangesHistoryTableFields() {
      return [
        {key: "id", label: 'ID' },
        {key: "userName", label: 'Nazwa Użytkownika' },
        {key: "data", label: 'Data Utworzenia' },
        {key: "typ", label: 'Typ' },
        {key: "status", label: 'Status' },
      ]
    },

    async loadWithdrawalsHistory() {
      let page = this.withdrawalsTable.currentPage - 1;
      if (page > 0) {
        page = this.withdrawalsTable.currentPage * this.withdrawalsTable.perPage - this.withdrawalsTable.perPage;
      }

      const result = await axios.get(`/ewallet/withdrawal/pagination/${this.shop.id}`, {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        },
        data: {},
        params: {
          "page": page,
          "size": this.withdrawalsTable.perPage,
          "statuses": ["COMPLETED"].join(',')
        }
      });

      this.withdrawalsTable.elements = result.data.withdrawals;
      this.withdrawalsTable.totalRows = result.data.count;
      this.withdrawalsTable.rows = result.data.count;

      if (this.mounted) {
        this.$refs.withdrawalsTable.refresh()
      }

      return this.withdrawalsTable.elements;
    },

    deleteWithdrawal(withdrawal) {
      axios.delete(`/ewallet/withdrawal/${withdrawal.id}`, {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        },
        data: {}
      }).then(() => {
        Swal.fire("Sukces!", "Wypłata została usunięta!", "success").then(async () => {
          await this.loadShop()
          this.$refs.withdrawalsTable.refresh()
        });
      }).catch((error) => {
        errorCatcher.catchErrors(error)
      })
    },

    changeStatus(withdrawal, status) {
      const json = JSON.stringify({
        "status": status
      })

      axios.post(`/ewallet/withdrawal/${withdrawal.id}`, json, {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        },
        data: {}
      }).then(() => {
        Swal.fire("Sukces!", "Status został zmieniony!", "success").then(async () => {
          await this.loadShop()
          this.$refs.withdrawalsTable.refresh()
        });
      }).catch((error) => {
        errorCatcher.catchErrors(error)
      })
    },

    async loadDeposits() {
      let page = this.depositsTable.currentPage - 1;
      if (page > 0) {
        page = this.depositsTable.currentPage * this.depositsTable.perPage - this.depositsTable.perPage;
      }

      const { data } = await axios.get(`/ewallet/deposit/pagination/${this.shop.id}`, {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        },
        data: {},
        params: {
          "page": page,
          "size": this.depositsTable.perPage
        }
      });

      this.depositsTable.elements = data.deposits;
      this.depositsTable.totalRows = data.count;
      this.depositsTable.rows = data.count;

      if (this.mounted) {
        this.$refs.depositsTable.refresh()
      }

      return this.depositsTable.elements;
    },

    deleteDeposit(deposit) {
      axios.delete(`/ewallet/deposit/${deposit.id}`, {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        },
        data: {}
      }).then(() => {
        Swal.fire("Sukces!", "Wpłata została usunięta!", "success").then(async () => {
          await this.loadShop()
          this.$refs.depositsTable.refresh()
        });
      }).catch((error) => {
        errorCatcher.catchErrors(error)
      })
    },

    async loadShopCosts() {
      let page = this.shopCostsTable.currentPage - 1;
      if (page > 0) {
        page = this.shopCostsTable.currentPage * this.shopCostsTable.perPage - this.shopCostsTable.perPage;
      }

      const result = await axios.get(`/ewallet/shop-cost/pagination/${this.shop.id}`, {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        },
        data: {},
        params: {
          "page": page,
          "size": this.shopCostsTable.perPage
        }
      });

      this.shopCostsTable.elements = result.data.shopCosts;
      this.shopCostsTable.totalRows = result.data.count;
      this.shopCostsTable.rows = result.data.count;

      if (this.mounted) {
        this.$refs.shopCostsTable.refresh()
      }

      return this.shopCostsTable.elements;
    },

    deleteShopCost(shopCost) {
      axios.delete(`/ewallet/shop-cost/${shopCost.id}`, {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        },
        data: {}
      }).then(() => {
        Swal.fire("Sukces!", "Koszt sklepu został usunięty!", "success").then(async () => {
          await this.loadShop()
          this.$refs.shopCostsTable.refresh()
        });
      }).catch((error) => {
        errorCatcher.catchErrors(error)
      })
    },

    async loadWithdrawalsOrders() {
      let page = this.withdrawalOrdersTable.currentPage - 1;
      if (page > 0) {
        page = this.withdrawalOrdersTable.currentPage * this.withdrawalOrdersTable.perPage - this.withdrawalOrdersTable.perPage;
      }

      const result = await axios.get(`/ewallet/withdrawal/pagination/${this.shop.id}`, {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        },
        data: {},
        params: {
          "page": page,
          "size": this.withdrawalOrdersTable.perPage,
          "statuses": ["PENDING", "VERIFIED", "ACCEPTED"].join(',')
        }
      });

      this.withdrawalOrdersTable.elements = result.data.withdrawals;
      this.withdrawalOrdersTable.totalRows = result.data.count;
      this.withdrawalOrdersTable.rows = result.data.count;

      if (this.mounted) {
        this.$refs.withdrawalsOrdersTable.refresh()
      }

      return this.withdrawalOrdersTable.elements;
    },

    openAddDepositModal() {
      this.modals.addDeposit.visible = true
    },

    hideAddDepositModal() {
      this.modals.addDeposit.visible = false
      this.modals.addDeposit.form.amount = 0
      this.modals.addDeposit.form.description = ""
    },

    addDeposit() {
      this.submitted = true

      this.$v.modals.addDeposit.form.$touch();
      if (this.$v.modals.addDeposit.form.$invalid) {
        return;
      }

      const json = JSON.stringify({
        "shopId": this.shop.id,
        "amount": this.modals.addDeposit.form.amount,
        "description": this.modals.addDeposit.form.description
      })

      axios.post(`/ewallet/deposit`, json, {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        },
      }).then(() => {
        this.hideAddDepositModal()

        Swal.fire("Sukces!", "Wpłata została dodana pomyślnie!", "success").then(async () => {
          await this.loadShop()
          this.$refs.depositsTable.refresh()
        });

        this.submitted = false;
      }).catch((error) => {
        errorCatcher.catchErrors(error)
        this.submitted = false;
      })
    },

    openAddShopCostModal() {
      this.modals.addShopCost.visible = true
    },

    hideAddShopCostModal() {
      this.modals.addShopCost.visible = false
      this.modals.addShopCost.form.cost = 0
      this.modals.addShopCost.form.description = ""
    },

    addShopCost() {
      this.submitted = true

      this.$v.modals.addShopCost.form.$touch();
      if (this.$v.modals.addShopCost.form.$invalid) {
        return;
      }

      const json = JSON.stringify({
        "shopId": this.shop.id,
        "cost": this.modals.addShopCost.form.cost,
        "description": this.modals.addShopCost.form.description
      })

      axios.post(`/ewallet/shop-cost`, json, {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        },
      }).then(() => {
        this.hideAddShopCostModal()

        Swal.fire("Sukces!", "Koszt sklepu został dodany pomyślnie!", "success").then(async () => {
          await this.loadShop()
          this.$refs.shopCostsTable.refresh()
        });

        this.submitted = false;
      }).catch((error) => {
        errorCatcher.catchErrors(error)
        this.submitted = false;
      })
    }

  },

  async created() {
    try {
      await this.loadShop()
      await this.loadEngines()
    } catch (error) {
      console.log(error)
    }
  }

};
</script>

<template>
  <Layout>
    <PageHeader :title="$t('core.shop.show.title', { 'shop_name': shop ? shop.name : '' })" :items="getItems()" />

    <div class="row">
      <div class="col-12">
        <div class="mb-2">
          <router-link to="/dashboard/admin/shops" class="btn btn-primary w-sm mb-2">
            {{ $t('page.back') }}
          </router-link>
        </div>
      </div>
    </div>

    <template v-if="shop && market">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="table-responsive">
              <table class="table table-bordered">
                <tbody>
                <tr>
                  <th class="w-50">ID Sklepu</th>
                  <td class="w-50">{{ shop.id }}</td>
                </tr>

                <tr v-if="user">
                  <th class="w-50">Nazwa użytkownika</th>
                  <td class="w-50">{{ user.firstName }} {{ user.lastName }} ({{ user.email }})</td>
                </tr>

                <tr v-if="shop.emailAddress">
                  <th class="w-50">Adres E-mail sklepu</th>
                  <td class="w-50">{{ shop.emailAddress }}</td>
                </tr>

                <tr v-if="shop.domain">
                  <th class="w-50">Adres sklepu</th>
                  <td class="w-50">{{ shop.domain }}</td>
                </tr>

                <tr v-if="engines">
                  <th class="w-50">Nazwa platformy</th>
                  <td class="w-50">{{ $t(getEngine(shop.engineId).i18nTranslation) }}</td>
                </tr>

                <tr v-if="industry">
                  <th class="w-50">Branża
                    <b-button variant="success" class="mx-2 btn-sm" @click="openEditElementInTableModal(shop, 'industryId', 'Branża sklepu', 'INDUSTRY', 'Zmiana branży sklepu')">Zmień</b-button>
                  </th>
                  <td class="w-50">{{ industry.name }}</td>
                </tr>

                <tr>
                  <th>Przewidywany zysk</th>
                  <td>{{ priceHelper.format(eWallet.potentialProfit, market.currency) }}</td>
                </tr>

                <tr>
                  <th>Realny zysk</th>
                  <td>{{ priceHelper.format((eWallet.realProfit + eWallet.payments), market.currency) }}</td>
                </tr>

                <tr>
                  <th>Suma kosztów</th>
                  <td>{{  priceHelper.format(eWallet.costs, market.currency) }}</td>
                </tr>

                <tr>
                  <th>Suma wypłat</th>
                  <td>{{ priceHelper.format(eWallet.withdrawals, market.currency) }}</td>
                </tr>

                <tr>
                  <th>Suma wpłat</th>
                  <td>{{  priceHelper.format(eWallet.payments, market.currency) }}</td>
                </tr>

                <tr>
                  <th>Bilans (Realny zysk - wypłaty i koszty + wpłaty)</th>
                  <td>{{ priceHelper.format(eWallet.realProfit - eWallet.withdrawals - eWallet.costs + eWallet.payments, market.currency) }}</td>
                </tr>

                <tr>
                  <th>Zysk przewidywany + Realny</th>
                  <td>{{ priceHelper.format(eWallet.potentialProfit + eWallet.realProfit, market.currency) }}</td>
                </tr>

<!--                <tr>-->
<!--                  <th>Tylko wtyczka</th>-->
<!--                  <td>TODO</td>-->
<!--                </tr>-->

<!--                <tr>-->
<!--                  <th>Sklep w programie jedna paczka</th>-->
<!--                  <td>TODO</td>-->
<!--                </tr>-->

<!--                <tr>-->
<!--                  <th>Firma obsługująca</th>-->
<!--                  <td>TODO</td>-->
<!--                </tr>-->

                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="button-items">
<!--              <b-button variant="primary">Dodaj wypłatę</b-button>-->
              <b-button variant="primary" @click="openAddDepositModal">Dodaj wpłatę</b-button>
              <b-button variant="primary" @click="openAddShopCostModal">Dodaj koszty sklepu</b-button>
            </div>
          </div>
        </div>
      </div>

      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <h5>Historia wypłat</h5>

                <div class="py-4">
                  <div class="row mt-4">
                    <div class="col-sm-12 col-md-6">
                      <div id="tickets-table_length" class="dataTables_length">
                        <label class="d-inline-flex align-items-center">
                          {{ $t('table.show') }}&nbsp;
                          <b-form-select v-model="withdrawalsTable.perPage" size="sm" :options="withdrawalsTable.pageOptions"></b-form-select>&nbsp;{{ $t('table.entries') }}
                        </label>
                      </div>
                    </div>

                    <!-- Search -->
                    <div class="col-sm-12 col-md-6">
                      <div id="tickets-table_filter" class="dataTables_filter text-md-right">
                        <label class="d-inline-flex align-items-center">
                          {{ $t('table.search') }}
                          <b-form-input
                              v-model="withdrawalsTable.filter"
                              type="search"
                              class="form-control form-control-sm ml-2"
                              @keyup.enter="setToFirstPageAndRefresh(withdrawalsTable, 'withdrawalsTable')"
                          ></b-form-input>
                        </label>
                      </div>
                    </div>
                    <!-- End search -->
                  </div>

                  <div class="row">
                    <div class="col">
                      <div class="float-right">
                        <label class="d-inline-flex align-items-center mx-2">
                          <b-form-input v-model="withdrawalsTable.inputPage" class="form-control form-control-sm ml-2" />
                        </label>

                        <b-button variant="primary" @click="paginationHelper.setCurrentPage(withdrawalsTable, withdrawalsTable.inputPage)">{{ $t('table.go-to-page') }}</b-button>
                      </div>
                    </div>
                  </div>

                  <div class="row py-3">
                    <div class="col">
                      <div class="dataTables_paginate paging_simple_numbers float-right">
                        <ul class="pagination pagination-rounded mb-0">
                          <b-pagination v-model="withdrawalsTable.currentPage" :total-rows="withdrawalsTable.rows" :per-page="withdrawalsTable.perPage"></b-pagination>
                        </ul>
                      </div>
                    </div>
                  </div>

                  <div class="table-responsive">
                    <b-table
                        ref="withdrawalsTable"
                        :items="loadWithdrawalsHistory"
                        :fields="getWithdrawalsTableFields()"
                        responsive="sm"
                        :per-page="withdrawalsTable.perPage"
                        :current-page="withdrawalsTable.currentPage"
                        :sort-by.sync="withdrawalsTable.sortBy"
                        :sort-desc.sync="withdrawalsTable.sortDesc"
                        :filter="withdrawalsTable.filter"
                        :filter-included-fields="withdrawalsTable.filterOn"
                        @filtered="elements => paginationHelper.onFiltered(withdrawalsTable, elements)"
                        :empty-text="$t('message.no-elements')"
                        :empty-filtered-text="$t('message.no-records')" :show-empty="true"
                        :busy.sync="withdrawalsTable.isBusy">
                      <div slot="table-busy" class="text-center">
                        <h5>{{ $t('message.loading') }}</h5>
                        <span aria-hidden="true" class="m-2 spinner-border text-primary"></span>
                      </div>

                      <template v-slot:cell(actions)="{ item }">
                        <div class="button-items">
                          <a v-if="item.invoiceFileUrl" class="btn btn-primary btn-sm" :href="item.invoiceFileUrl">Pobierz fakturę</a>
                          <b-button variant="danger" class="btn-sm" @click="deleteWithdrawal(item)">Usuń</b-button>
                        </div>
                      </template>

                    </b-table>
                  </div>

                  <div class="row">
                    <div class="col">
                      <div class="float-left">
                        <p>{{ $t('table.entries-footer', { 'amount': paginationHelper.getElementsAmount(withdrawalsTable), 'elements': paginationHelper.getElementsCount(withdrawalsTable), 'all': withdrawalsTable.totalRows }) }}</p>
                      </div>

                      <div class="dataTables_paginate paging_simple_numbers float-right">
                        <ul class="pagination pagination-rounded mb-0">
                          <!-- pagination -->
                          <b-pagination v-model="withdrawalsTable.currentPage" :total-rows="withdrawalsTable.rows" :per-page="withdrawalsTable.perPage"></b-pagination>
                        </ul>
                      </div>
                    </div>
                  </div>
          </div>
        </div>
      </div>
      </div>

      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <h5>Historia wpłat</h5>

            <div class="py-4">
              <div class="row mt-4">
                <div class="col-sm-12 col-md-6">
                  <div id="tickets-table_length" class="dataTables_length">
                    <label class="d-inline-flex align-items-center">
                      {{ $t('table.show') }}&nbsp;
                      <b-form-select v-model="depositsTable.perPage" size="sm" :options="depositsTable.pageOptions"></b-form-select>&nbsp;{{ $t('table.entries') }}
                    </label>
                  </div>
                </div>

                <!-- Search -->
                <div class="col-sm-12 col-md-6">
                  <div id="tickets-table_filter" class="dataTables_filter text-md-right">
                    <label class="d-inline-flex align-items-center">
                      {{ $t('table.search') }}
                      <b-form-input
                          v-model="depositsTable.filter"
                          type="search"
                          class="form-control form-control-sm ml-2"
                          @keyup.enter="setToFirstPageAndRefresh(depositsTable, 'depositsTable')"
                      ></b-form-input>
                    </label>
                  </div>
                </div>
                <!-- End search -->
              </div>

              <div class="row">
                <div class="col">
                  <div class="float-right">
                    <label class="d-inline-flex align-items-center mx-2">
                      <b-form-input v-model="depositsTable.inputPage" class="form-control form-control-sm ml-2" />
                    </label>

                    <b-button variant="primary" @click="paginationHelper.setCurrentPage(depositsTable, depositsTable.inputPage)">{{ $t('table.go-to-page') }}</b-button>
                  </div>
                </div>
              </div>

              <div class="row py-3">
                <div class="col">
                  <div class="dataTables_paginate paging_simple_numbers float-right">
                    <ul class="pagination pagination-rounded mb-0">
                      <b-pagination v-model="depositsTable.currentPage" :total-rows="depositsTable.rows" :per-page="depositsTable.perPage"></b-pagination>
                    </ul>
                  </div>
                </div>
              </div>

              <div class="table-responsive">
                <b-table

                    ref="depositsTable"
                    :items="loadDeposits"
                    :fields="getDepositsTableFields()"
                    responsive="sm"
                    :per-page="depositsTable.perPage"
                    :current-page="depositsTable.currentPage"
                    :sort-by.sync="depositsTable.sortBy"
                    :sort-desc.sync="depositsTable.sortDesc"
                    :filter="depositsTable.filter"
                    :filter-included-fields="depositsTable.filterOn"
                    @filtered="elements => paginationHelper.onFiltered(depositsTable, elements)"
                    :empty-text="$t('message.no-elements')"
                    :empty-filtered-text="$t('message.no-records')" :show-empty="true"
                    :busy.sync="depositsTable.isBusy">
                  <div slot="table-busy" class="text-center">
                    <h5>{{ $t('message.loading') }}</h5>
                    <span aria-hidden="true" class="m-2 spinner-border text-primary"></span>
                  </div>

                  <template v-slot:cell(actions)="{ item }">
                    <b-button variant="danger" class="btn-sm" @click="deleteDeposit(item)">Usuń</b-button>
                  </template>

                </b-table>
              </div>

              <div class="row">
                <div class="col">
                  <div class="float-left">
                    <p>{{ $t('table.entries-footer', { 'amount': paginationHelper.getElementsAmount(depositsTable), 'elements': paginationHelper.getElementsCount(depositsTable), 'all': depositsTable.totalRows }) }}</p>
                  </div>

                  <div class="dataTables_paginate paging_simple_numbers float-right">
                    <ul class="pagination pagination-rounded mb-0">
                      <!-- pagination -->
                      <b-pagination v-model="depositsTable.currentPage" :total-rows="depositsTable.rows" :per-page="depositsTable.perPage"></b-pagination>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <h5>Zlecenia wypłat</h5>

            <div class="py-4">
              <div class="row mt-4">
                <div class="col-sm-12 col-md-6">
                  <div id="tickets-table_length" class="dataTables_length">
                    <label class="d-inline-flex align-items-center">
                      {{ $t('table.show') }}&nbsp;
                      <b-form-select v-model="withdrawalOrdersTable.perPage" size="sm" :options="withdrawalOrdersTable.pageOptions"></b-form-select>&nbsp;{{ $t('table.entries') }}
                    </label>
                  </div>
                </div>

                <!-- Search -->
                <div class="col-sm-12 col-md-6">
                  <div id="tickets-table_filter" class="dataTables_filter text-md-right">
                    <label class="d-inline-flex align-items-center">
                      {{ $t('table.search') }}
                      <b-form-input
                          v-model="withdrawalOrdersTable.filter"
                          type="search"
                          class="form-control form-control-sm ml-2"
                          @keyup.enter="setToFirstPageAndRefresh(withdrawalOrdersTable, 'withdrawalsOrdersTable')"
                      ></b-form-input>
                    </label>
                  </div>
                </div>
                <!-- End search -->
              </div>

              <div class="row">
                <div class="col">
                  <div class="float-right">
                    <label class="d-inline-flex align-items-center mx-2">
                      <b-form-input v-model="withdrawalOrdersTable.inputPage" class="form-control form-control-sm ml-2" />
                    </label>

                    <b-button variant="primary" @click="paginationHelper.setCurrentPage(withdrawalOrdersTable, withdrawalOrdersTable.inputPage)">{{ $t('table.go-to-page') }}</b-button>
                  </div>
                </div>
              </div>

              <div class="row py-3">
                <div class="col">
                  <div class="dataTables_paginate paging_simple_numbers float-right">
                    <ul class="pagination pagination-rounded mb-0">
                      <b-pagination v-model="withdrawalOrdersTable.currentPage" :total-rows="withdrawalOrdersTable.rows" :per-page="withdrawalOrdersTable.perPage"></b-pagination>
                    </ul>
                  </div>
                </div>
              </div>

              <div class="table-responsive">
                <b-table

                    ref="withdrawalsOrdersTable"
                    :items="loadWithdrawalsOrders"
                    :fields="getWithdrawalOrdersTableFields()"
                    responsive="sm"
                    :per-page="withdrawalOrdersTable.perPage"
                    :current-page="withdrawalOrdersTable.currentPage"
                    :sort-by.sync="withdrawalOrdersTable.sortBy"
                    :sort-desc.sync="withdrawalOrdersTable.sortDesc"
                    :filter="withdrawalOrdersTable.filter"
                    :filter-included-fields="withdrawalOrdersTable.filterOn"
                    @filtered="elements => paginationHelper.onFiltered(withdrawalOrdersTable, elements)"
                    :empty-text="$t('message.no-elements')"
                    :empty-filtered-text="$t('message.no-records')" :show-empty="true">
                  <template v-slot:cell(status)="{ item }">
                    <span class="badge" :class="$t(`e-wallet.withdrawals.status-badge.${item.status}`)">{{ $t(`e-wallet.withdrawals.status.${item.status}`) }}</span>
                  </template>

                  <template v-slot:cell(actions)="{ item }">
                    <div class="button-items">
                      <a v-if="item.invoiceFileUrl" class="btn btn-primary btn-sm" :href="item.invoiceFileUrl">Pobierz fakturę</a>
                      <b-button variant="success" class="btn-sm" v-if="item.status !== 'VERIFIED'" @click="changeStatus(item, 'VERIFIED')">Zweryfikuj</b-button>
                      <b-button variant="success" class="btn-sm" v-if="item.status === 'VERIFIED'" @click="changeStatus(item, 'COMPLETED')">Zatwierdź</b-button>
                      <b-button variant="danger" class="btn-sm" @click="deleteWithdrawal(item)">Usuń</b-button>
                    </div>
                  </template>

                </b-table>
              </div>

              <div class="row">
                <div class="col">
                  <div class="float-left">
                    <p>{{ $t('table.entries-footer', { 'amount': paginationHelper.getElementsAmount(withdrawalOrdersTable), 'elements': paginationHelper.getElementsCount(withdrawalOrdersTable), 'all': withdrawalOrdersTable.totalRows }) }}</p>
                  </div>

                  <div class="dataTables_paginate paging_simple_numbers float-right">
                    <ul class="pagination pagination-rounded mb-0">
                      <!-- pagination -->
                      <b-pagination v-model="withdrawalOrdersTable.currentPage" :total-rows="withdrawalOrdersTable.rows" :per-page="withdrawalOrdersTable.perPage"></b-pagination>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <h5>Koszty sklepu</h5>

            <div class="py-4">
              <div class="row mt-4">
                <div class="col-sm-12 col-md-6">
                  <div id="tickets-table_length" class="dataTables_length">
                    <label class="d-inline-flex align-items-center">
                      {{ $t('table.show') }}&nbsp;
                      <b-form-select v-model="shopCostsTable.perPage" size="sm" :options="shopCostsTable.pageOptions"></b-form-select>&nbsp;{{ $t('table.entries') }}
                    </label>
                  </div>
                </div>

                <!-- Search -->
                <div class="col-sm-12 col-md-6">
                  <div id="tickets-table_filter" class="dataTables_filter text-md-right">
                    <label class="d-inline-flex align-items-center">
                      {{ $t('table.search') }}
                      <b-form-input
                          v-model="shopCostsTable.filter"
                          type="search"
                          class="form-control form-control-sm ml-2"
                          @keyup.enter="setToFirstPageAndRefresh(shopCostsTable, 'shopCostsTable')"
                      ></b-form-input>
                    </label>
                  </div>
                </div>
                <!-- End search -->
              </div>

              <div class="row">
                <div class="col">
                  <div class="float-right">
                    <label class="d-inline-flex align-items-center mx-2">
                      <b-form-input v-model="shopCostsTable.inputPage" class="form-control form-control-sm ml-2" />
                    </label>

                    <b-button variant="primary" @click="paginationHelper.setCurrentPage(shopCostsTable, shopCostsTable.inputPage)">{{ $t('table.go-to-page') }}</b-button>
                  </div>
                </div>
              </div>

              <div class="row py-3">
                <div class="col">
                  <div class="dataTables_paginate paging_simple_numbers float-right">
                    <ul class="pagination pagination-rounded mb-0">
                      <b-pagination v-model="shopCostsTable.currentPage" :total-rows="shopCostsTable.rows" :per-page="shopCostsTable.perPage"></b-pagination>
                    </ul>
                  </div>
                </div>
              </div>

              <div class="table-responsive">
                <b-table

                    ref="shopCostsTable"
                    :items="loadShopCosts"
                    :fields="getShopCostsTableFields()"
                    responsive="sm"
                    :per-page="shopCostsTable.perPage"
                    :current-page="shopCostsTable.currentPage"
                    :sort-by.sync="shopCostsTable.sortBy"
                    :sort-desc.sync="shopCostsTable.sortDesc"
                    :filter="shopCostsTable.filter"
                    :filter-included-fields="shopCostsTable.filterOn"
                    @filtered="elements => paginationHelper.onFiltered(shopCostsTable, elements)"
                    :empty-text="$t('message.no-elements')"
                    :empty-filtered-text="$t('message.no-records')" :show-empty="true"
                    :busy.sync="depositsTable.isBusy">
                  <div slot="table-busy" class="text-center">
                    <h5>{{ $t('message.loading') }}</h5>
                    <span aria-hidden="true" class="m-2 spinner-border text-primary"></span>
                  </div>


                  <template v-slot:cell(actions)="{ item }">
                    <b-button variant="danger" class="btn-sm" @click="deleteShopCost(item)">Usuń</b-button>
                  </template>
                </b-table>
              </div>

              <div class="row">
                <div class="col">
                  <div class="float-left">
                    <p>{{ $t('table.entries-footer', { 'amount': paginationHelper.getElementsAmount(shopCostsTable), 'elements': paginationHelper.getElementsCount(shopCostsTable), 'all': shopCostsTable.totalRows }) }}</p>
                  </div>

                  <div class="dataTables_paginate paging_simple_numbers float-right">
                    <ul class="pagination pagination-rounded mb-0">
                      <!-- pagination -->
                      <b-pagination v-model="shopCostsTable.currentPage" :total-rows="shopCostsTable.rows" :per-page="shopCostsTable.perPage"></b-pagination>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <h5>Historia zmian</h5>

            <div class="py-4">
              <div class="row mt-4">
                <div class="col-sm-12 col-md-6">
                  <div id="tickets-table_length" class="dataTables_length">
                    <label class="d-inline-flex align-items-center">
                      {{ $t('table.show') }}&nbsp;
                      <b-form-select v-model="changesHistoryTable.perPage" size="sm" :options="changesHistoryTable.pageOptions"></b-form-select>&nbsp;{{ $t('table.entries') }}
                    </label>
                  </div>
                </div>

                <!-- Search -->
                <div class="col-sm-12 col-md-6">
                  <div id="tickets-table_filter" class="dataTables_filter text-md-right">
                    <label class="d-inline-flex align-items-center">
                      {{ $t('table.search') }}
                      <b-form-input
                          v-model="changesHistoryTable.filter"
                          type="search"
                          class="form-control form-control-sm ml-2"
                          @keyup.enter="setToFirstPageAndRefresh(changesHistoryTable, 'changesHistoryTable')"
                      ></b-form-input>
                    </label>
                  </div>
                </div>
                <!-- End search -->
              </div>

              <div class="row">
                <div class="col">
                  <div class="float-right">
                    <label class="d-inline-flex align-items-center mx-2">
                      <b-form-input v-model="changesHistoryTable.inputPage" class="form-control form-control-sm ml-2" />
                    </label>

                    <b-button variant="primary" @click="paginationHelper.setCurrentPage(changesHistoryTable, changesHistoryTable.inputPage)">{{ $t('table.go-to-page') }}</b-button>
                  </div>
                </div>
              </div>

              <div class="row py-3">
                <div class="col">
                  <div class="dataTables_paginate paging_simple_numbers float-right">
                    <ul class="pagination pagination-rounded mb-0">
                      <b-pagination v-model="changesHistoryTable.currentPage" :total-rows="changesHistoryTable.rows" :per-page="changesHistoryTable.perPage"></b-pagination>
                    </ul>
                  </div>
                </div>
              </div>

              <div class="table-responsive">
                <b-table

                    ref="changesHistoryTable"
                    :items="changesHistoryTable.elements"
                    :fields="getChangesHistoryTableFields()"
                    responsive="sm"
                    :per-page="changesHistoryTable.perPage"
                    :current-page="changesHistoryTable.currentPage"
                    :sort-by.sync="changesHistoryTable.sortBy"
                    :sort-desc.sync="changesHistoryTable.sortDesc"
                    :filter="changesHistoryTable.filter"
                    :filter-included-fields="changesHistoryTable.filterOn"
                    @filtered="elements => paginationHelper.onFiltered(changesHistoryTable, elements)"
                    :empty-text="$t('message.no-elements')"
                    :empty-filtered-text="$t('message.no-records')" :show-empty="true">
                </b-table>
              </div>

              <div class="row">
                <div class="col">
                  <div class="float-left">
                    <p>{{ $t('table.entries-footer', { 'amount': paginationHelper.getElementsAmount(changesHistoryTable), 'elements': paginationHelper.getElementsCount(changesHistoryTable), 'all': changesHistoryTable.totalRows }) }}</p>
                  </div>

                  <div class="dataTables_paginate paging_simple_numbers float-right">
                    <ul class="pagination pagination-rounded mb-0">
                      <!-- pagination -->
                      <b-pagination v-model="changesHistoryTable.currentPage" :total-rows="changesHistoryTable.rows" :per-page="changesHistoryTable.perPage"></b-pagination>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <h5>Lista zamówień</h5>

            <orders-table :shop-id="shop.id" />
          </div>
        </div>
      </div>
    </template>
    <template v-else>
      <div class="text-center py-3">
        <h5>{{ $t('message.loading') }}</h5>
        <span aria-hidden="true" class="m-2 spinner-border text-primary"></span>
      </div>
    </template>

    <b-modal
        id="modal-1"
        v-model="modals.editElementInTable.visibleModal"
        hide-footer
        :title="`${modals.editElementInTable.title}`"
        title-class="font-18"
        @esc="hideEditElementInTableModal"
        @hide="hideEditElementInTableModal">

      <label v-if="modals.editElementInTable.elementName">{{ modals.editElementInTable.elementName }}</label>
      <template v-if="modals.editElementInTable.type === 'INT'">
        <input v-model.number="modals.editElementInTable.item[modals.editElementInTable.element]" type="number" class="form-control" />
      </template>
      <template v-else-if="modals.editElementInTable.type === 'STRING'">
        <input v-model="modals.editElementInTable.item[modals.editElementInTable.element]" type="text" class="form-control" />
      </template>
      <template v-else-if="modals.editElementInTable.type === 'INDUSTRY'">
        <ecat-multiselect @change="value => modals.editElementInTable.item[modals.editElementInTable.element] = value"
                          :can-unselect="true"
                          save-id="id"
                          view-id="name"
                          placeholder="Wybierz branże"
                          load-url="/industry/list/pagination"
                          query-url="/industry/by-name"
                          list-name="industries"
                          param="name">
        </ecat-multiselect>
      </template>
      <template v-else-if="modals.editElementInTable.type === 'ENGINE'">
        <select v-model="modals.editElementInTable.item[modals.editElementInTable.element]" class="custom-select">
          <option v-for="[key, value] in engines" :key="key" :value="key" >{{ $t(value.i18nTranslation) }}</option>
        </select>
      </template>

      <div class="text-center">
        <b-button class="mx-2" variant="success" @click="editShop">
          Zapisz
        </b-button>

        <b-button variant="danger" class="my-3" @click="hideEditElementInTableModal">
          {{ $t('message.cancel') }}
        </b-button>
      </div>
    </b-modal>

    <b-modal
        id="modal-2"
        v-model="modals.addDeposit.visible"
        hide-footer
        title="Dodawanie nowej wpłaty"
        title-class="font-18"
        @esc="hideAddDepositModal"
        @hide="hideAddDepositModal">
      <div class="form-group">
        <label>Kwota</label>
        <input v-model="modals.addDeposit.form.amount" type="number" class="form-control" :class="{ 'is-invalid': submitted && $v.modals.addDeposit.form.amount.$error }" />
        <div v-if="submitted && !$v.modals.addDeposit.form.amount.required" class="invalid-feedback">{{ $t('message.required')}}</div>
      </div>

      <div class="form-group">
        <label>Opis</label>
        <textarea v-model="modals.addDeposit.form.description" type="text" class="form-control" :class="{ 'is-invalid': submitted && $v.modals.addDeposit.form.description.$error }" />
        <div v-if="submitted && !$v.modals.addDeposit.form.description.required" class="invalid-feedback">{{ $t('message.required')}}</div>
      </div>

      <div class="text-center">
        <b-button class="mx-2" variant="success" @click="addDeposit">
          Zapisz
        </b-button>

        <b-button variant="danger" class="my-3" @click="hideAddDepositModal">
          {{ $t('message.cancel') }}
        </b-button>
      </div>
    </b-modal>

    <b-modal
        id="modal-2"
        v-model="modals.addShopCost.visible"
        hide-footer
        title="Dodawanie nowego kosztu sklepu"
        title-class="font-18"
        @esc="hideAddShopCostModal"
        @hide="hideAddShopCostModal">
      <div class="form-group">
        <label>Kwota</label>
        <input v-model="modals.addShopCost.form.cost" type="number" class="form-control" :class="{ 'is-invalid': submitted && $v.modals.addShopCost.form.cost.$error }" />
        <div v-if="submitted && !$v.modals.addShopCost.form.cost.required" class="invalid-feedback">{{ $t('message.required')}}</div>
      </div>

      <div class="form-group">
        <label>Opis</label>
        <textarea v-model="modals.addShopCost.form.description" type="text" class="form-control" :class="{ 'is-invalid': submitted && $v.modals.addShopCost.form.description.$error }" />
        <div v-if="submitted && !$v.modals.addShopCost.form.description.required" class="invalid-feedback">{{ $t('message.required')}}</div>
      </div>

      <div class="text-center">
        <b-button class="mx-2" variant="success" @click="addShopCost">
          Zapisz
        </b-button>

        <b-button variant="danger" class="my-3" @click="hideAddShopCostModal">
          {{ $t('message.cancel') }}
        </b-button>
      </div>
    </b-modal>

  </Layout>
</template>